import styled from "styled-components"
import { device } from "breakpoints"
import { Carousel } from "../Carousel"
import {
  SlidesContainer,
  ButtonsContainer,
  DotsContainer,
  DotButton,
} from "../Carousel/style"

import { Teaser } from "../Teaser"
import {
  ImageContainer,
  Title,
  Excerpt,
  Tag,
  InfoContainer,
} from "../Teaser/style"

export const Container = styled.div`
  margin: 30px;
  margin-bottom: 80px;

  max-width: 1160px;

  @media ${device.desktop} {
    margin: 60px auto 0;
  }
`
export const MainTitle = styled.h2`
  font-size: 28px;
  color: #2a3449;
  letter-spacing: -0.44px;
  text-align: center;
  line-height: 28px;
  margin-bottom: 30px;

  @media ${device.desktop} {
    font-size: 36px;
    letter-spacing: -0.56px;
    line-height: 28px;
    margin-bottom: 60px;
  }
`

export const AllTopics = styled.div`
  display: none;
`

export const NewCarousel = styled(Carousel)`
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: visible;

  @media ${device.desktop} {
    box-shadow: none;
    .embla-carousel {
      overflow: visible !important;
      padding-right: 0;
    }
  }
  .embla-carousel {
    @media ${device.desktop} {
      padding-bottom: 50px;
    }
  }

  ${SlidesContainer} {
    @media ${device.desktop} {
      .is-selected {
        left: 0 !important;
      }
    }
  }
  ${ButtonsContainer} {
    display: none;
    margin-top: 68px !important;
  }
  ${DotsContainer} {
    position: absolute;
    bottom: -40px;
    margin-top: 0;
    margin-left: 0;
    height: 6px;

    @media ${device.desktop} {
      display: none;
    }
  }
  ${DotButton} {
    width: 6px;
    height: 6px;
  }
`

export const NewTeaser = styled(Teaser)`
  border: none;
  background: #ffffff;
  box-shadow: none;
  height: 100%;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);

  @media ${device.desktop} {
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.15);
  }

  ${ImageContainer} {
    padding-top: 56%;
    margin-bottom: 0;
  }
  ${Title} {
    font-size: 20px;
    color: #2a3449;
    letter-spacing: -0.31px;
    text-align: center;
    line-height: 24px;

    @media ${device.desktop} {
      font-size: 24px;
      letter-spacing: -0.38px;
      text-align: center;
      line-height: 28px;
    }
  }
  ${InfoContainer} {
    padding: 0 32px 45px;
    justify-content: flex-start;

    @media ${device.desktop} {
      padding: 0 25px 35px;
      padding-top: 0;
    }
  }

  ${Excerpt} {
    font-size: 16px;
    color: #5c6370;
    letter-spacing: -0.25px;
    text-align: center;
    line-height: 24px;

    @media ${device.desktop} {
      font-size: 18px;
      letter-spacing: -0.28px;
      text-align: center;
      line-height: 27px;
    }
  }
  ${Tag} {
    font-weight: 700;
    background: #f17071;
    border-radius: 75px;
    /* position: absolute;
    top: -15px; */
    padding: 7px 17px;
    order: -2;
    transform: translateY(-50%);
    font-size: 10px;
    color: #ffffff;
    letter-spacing: 0.42px;
    text-align: center;
    /* line-height: 27px; */
    text-transform: uppercase;

    @media ${device.desktop} {
      padding: 7px 17px;

      font-size: 12px;
      color: #ffffff;
      letter-spacing: 0.5px;
      text-align: center;
      /* line-height: 27px; */
    }
  }
`
