import React, { useState, useEffect, useCallback } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { InternalPage } from "components/InternalPage"
import { useStaticQuery, graphql } from "gatsby"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import { NewTeaser } from "../components/TeaserNews/style"
import { Pagination } from "components/Pagination"
import useMedia from "hooks/useMedia.js"
import algoliaIndex from "utils/algoliaIndex"
import history from "components/Pagination/history"
import { useDidMountEffect } from "utils/helpers/useDidMountEffect"

const NewsPage = ({ pageContext }) => {
  const { title, featuredImage, previewSQ, depth } = pageContext

  const isDesktop = useMedia(
    // Media queries
    ["(min-width: 1024px)"],
    //options
    [true],
    // default
    false
  )
  const data = useStaticQuery(
    graphql`
      query {
        posts: wpgraphql {
          posts(first: 100) {
            nodes {
              title
              excerpt
              link
              slug
              featuredImage {
                sourceUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
              categories {
                nodes {
                  name
                }
              }
            }
          }
        }

        categories: wpgraphql {
          categories(where: { exclude: [310, 1] }) {
            nodes {
              name
              slug
            }
          }
        }
      }
    `
  )
  const latestPosts = previewSQ?.latestPosts?.nodes || data?.posts?.posts?.nodes

  const categories =
    previewSQ?.categories?.nodes || data?.categories?.categories?.nodes

  const [currentPage, setCurrentPage] = useState(1)
  const [currentPosts, setCurrentPosts] = useState(latestPosts)
  const [totalPosts, setTotalPosts] = useState(latestPosts.length)
  const [filter, setFilter] = useState(null)
  const [limitFirstPosts, setLimitFirstPosts] = useState(50)
  const [algoliaRan, setAlgoliaRan] = useState(false)
  const postsPerPage = 12

  const findResults = useCallback(() => {
    algoliaIndex
      .search({
        page: currentPage - 1,
        hitsPerPage: postsPerPage,
        facetFilters: [
          filter && filter.value === "all"
            ? ""
            : filter
            ? `categories: ${filter.label.replace("&", "&amp;")}`
            : "",
        ],
      })
      .then(data => {
        setCurrentPosts(data.hits)
        setTotalPosts(data.nbHits)
        // setTotalPages(data.nbPages)
      })
  }, [currentPage, filter])

  const pushToHistory = (page, filter) => {
    history.push(
      {
        pathname: `${window.location.pathname}`,
        search: `?p=${page}${filter ? `&f=${filter.value}` : ""}`,
      },
      { ...history.location.state, page: page, filter: filter?.value }
    )
  }

  useEffect(() => {
    setLimitFirstPosts(12)
  }, [])

  useDidMountEffect(() => {
    findResults()
    setAlgoliaRan(true)
  }, [findResults])

  return (
    <Layout
      internal
      headerTitle={"Latest news"}
      headerImg={featuredImage}
      preview={previewSQ}
      landing
      depth={depth}
    >
      <SEO title={title} image={featuredImage?.sourceUrl} />
      <InternalPage
        news
        landing
        title={"Latest news"}
        categories={categories}
        setFilter={setFilter}
        setCurrentPage={setCurrentPage}
      />
      <FlexGrid gap={[30, 30, 40]}>
        <FlexRow>
          {currentPosts.slice(0, limitFirstPosts).map((post, index) => {
            return (
              <FlexCol key={index} xs={12} sm={6} md={4}>
                <NewTeaser
                  title={post.title}
                  img={post.featuredImage}
                  excerpt={post.excerpt}
                  link={post.link}
                  tag={
                    algoliaRan
                      ? post.categories[0]
                      : post?.categories?.nodes[0]?.name
                  }
                  centered
                />
              </FlexCol>
            )
          })}
        </FlexRow>
      </FlexGrid>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        maxNumbersVisible={isDesktop ? 5 : 3}
        postsPerPage={postsPerPage}
        totalPosts={totalPosts}
        filter={filter}
        setFilter={setFilter}
        pushToHistory={pushToHistory}
      />
    </Layout>
  )
}

export default NewsPage
