import styled from "styled-components"
import { CustomButton1 } from "../customButtons"
import { device } from "utils/breakpoints"
export const PaginationContainer = styled.div`
  display: flex;
  opacity: ${props => (props.noResults || props.hide ? "0" : "1")};
  transition: 140ms;
  align-items: center;
  margin: 0 auto;
  margin-top: 40px;
  justify-content: center;
  margin-bottom: 40px;

  @media ${device.desktop} {
    margin-top: 60px;
    margin-bottom: 120px;
  }
`
export const PageNumberList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0 10px;

  @media ${device.desktop} {
    margin: 0 25px;
  }
`

export const ListItem = styled.li`
  display: ${props => (props.hidden ? "none" : "block")};
  position: relative;
`

export const Number = styled.button`
  outline: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  color: ${props => (props.active ? "white" : "#29334B")};
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.active ? props.theme.primaryColor : "none")};
  border: none;
  border-radius: 50%;
  transition: 80ms;
  font-weight: 600;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const Skip = styled(Number)`
  height: auto;
  width: auto;
`

export const NextButton = styled(CustomButton1)`
  /* display: inline-flex; */
  /* background: ${props => props.theme.primaryColor}; */
  /* border-radius: 2px;
  font-weight: 600;
  outline: none;
  border: none;
  font-size: 14px;
  padding: 0 18px;
  height: 30px;
  width: 50px;
  color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center; */
  pointer-events: ${props => props.disabled && "none"};
  border-color: ${props => props.disabled && "#D0D6E4"};
  color: ${props => props.disabled && "#D0D6E4"};
  

  /* :hover {
    background: ${props => props.theme.btnHoverColor};
    transition: 90ms;
  }

  @media (min-width: 380px) {
    width: 66px;
  } */
`

export const PreviousButton = styled(NextButton)``
